<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-container fluid>
            <v-row>
              <v-col class="text-center text-h4 pa-5">
                People
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-lg-end text-center">
                <v-btn color="primary" lg="3" class="px-10" @click="addPeople()">
                  Add People
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="3">
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
              </v-col>
              <v-col cols="12">
                <v-data-table :headers="fields" :items="peopleList" :search="search" hide-default-footer mobile-breakpoint="0" :loading="loading" loading-text="Loading Data ...">
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn small color="primary" class="pa-0 ma-1" @click="$router.push(`people/statement/${item.id}`)">
                      <v-icon small>
                        mdi-eye
                      </v-icon>
                    </v-btn>
                    <v-btn small color="success" class="pa-0 ma-1" @click="$router.push(`/people/update/${item.id}`)">
                      <v-icon small>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                    <v-btn small color="error" class="pa-0 ma-1" @click="showDialog(item)">
                      <v-icon small>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:[`top`]>
                    <v-dialog v-model="showdialog" max-width="500px">
                      <v-card class="pa-5">
                        <v-card-title class="text-h6">Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="secondary" class="px-5 mr-2" @click="showdialog=false">Cancel</v-btn>
                          <v-btn color="primary" class="px-5" @click="deletePeople()">OK</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </v-data-table>
              </v-col>
              <v-col class="test-center mb-5">
                <v-pagination v-model="current_page" :length="total_pages" @next="currentPage()" @previous="currentPage()" @input="currentPage()"></v-pagination> 
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {get_people_list,delete_people} from '@api/people'
export default {
  data(){
    return {
      fields:[
          {
            text:"Name",
            value:"name",
          },
          {
            text:"Contact",
            value:"contact_number",
          },
          {
            text:"Balance",
            value:"balance",
          },
          { 
            text:"Total Borrowed",
            value:"total_borrowed_sum_amount",
          },
          { 
            text:"Total Recieved",
            value:"total_received_sum_amount",
          },
          { 
            text:"Actions",
            value:"actions",
            align:"center",
          }
        ],
        search:'',
        showdialog: false,
        person_id:"",
        total_pages:null,
        current_page:null,
        loading: false,
        peopleList:[],
        pagination:{}
    }
  },
  mounted(){
    this.getPeopleList();
  },
  methods: {
    currentPage(){
      var p=Number(this.current_page)
      this.getPeopleList({page:p})
    },
    getPeopleList(data){
      this.loading= true;
      get_people_list(data)
      .then(resp=>{
        this.loading= false;
        if(resp.status==='Success'){
          this.peopleList= this.setPeopleList(resp.data.data);
          this.total_pages=resp.data.last_page;
          this.current_page=resp.data.current_page;
        }
      })
    },
    setPeopleList(data){
      data.forEach(p=>{
        p.total_borrowed_sum_amount=p.total_borrowed_sum_amount===null?0:p.total_borrowed_sum_amount;
        p.total_received_sum_amount=p.total_received_sum_amount===null?0:p.total_received_sum_amount;
      })
      return data;
    },
    addPeople(){
      this.$router.push('/addpeople');
    },
    showDialog(item){
      this.person_id = item.id;
      this.showdialog=true;
    },
    deletePeople(){
      delete_people(this.person_id).then(resp=>{
        if(resp.status === 'Success'){
          this.$toastr.s(resp.message);
        }else{
          this.$toastr.e(resp.errors);
        }
      })
      this.showdialog=false;
      let p=Number(this.current_page);
      this.getPeopleList({page:p});
    }
  }
}
</script>

<style>

</style>
