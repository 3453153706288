import http from '@util/http';
export async function create_people(data){
    return (await http.post('/people/create',data)).data
}
export async function get_people_list(data){
    return (await http.get('/people/list',{params:data})).data
}
export async function get_people_detail(id){
    return (await http.get(`/people/edit/${id}`)).data
}
export async function update_people(data){
    return (await http.post(`/people/update/${data.id}`,data.data)).data
}
export async function delete_people(id){
    return (await http.delete(`/people/delete/${id}`)).data
}
export async function get_all_people(){
    return (await http.get('/people/get/all/peoples')).data
}
export async function get_people_statement(data){
    return (await http.get(`/transaction/people/${data.id}`,{params: data.data})).data
}